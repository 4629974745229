import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactPixel from 'react-facebook-pixel';

import { getAnalytics } from 'firebase/analytics';
import { app } from '../firebaseConfig';

import { Link } from 'react-router-dom';

export default function CookieBanner() {
  const [cookies, setCookies] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [dismiss, setDismiss] = React.useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    setCookies(cookieConsent);
    setLoading(false);
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setCookies('accepted');
    ReactPixel.grantConsent();
    ReactPixel.pageView();
    getAnalytics(app); // Initialize Firebase Analytics
  };

  const handleReject = () => {
    Cookies.set('cookieConsent', 'rejected', { expires: 30 });
    setCookies('rejected');
  };

  return (
    <>
      {!loading && !dismiss && cookies === undefined && (
        <div className="fixed bottom-0 end-0 z-[60] w-full sm:w-auto mx-auto p-6">
          <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-lg">
            <div className="flex flex-col sm:flex-row sm:items-center gap-y-3 sm:gap-y-0 sm:gap-x-5">
              {/* Text Section */}
              <div className="grow">
                <p className="text-gray-500 text-center">
                  Help us improve Scrybe by accepting our {''}
                  <Link className="font-bold font-inknut text-sm whitespace-nowrap text-gray-800 underline hover:text-gray-500" to="/cookie-policy">
                    Cookie Policy
                  </Link>
                  .
                </p>
              </div>

              {/* Buttons & Close Icon Container */}
              <div className="flex flex-row items-center gap-x-2 w-full sm:w-auto">
                {/* Reject Button */}
                <button
                  onClick={handleReject}
                  type="button"
                  className="
              flex-1
              sm:flex-none
			  justify-center
              py-2
              px-3
              inline-flex
              items-center
              gap-x-2
              text-sm
              font-medium
              rounded-lg
              border
              border-gray-200
              bg-white
              text-gray-800
              shadow-sm
              hover:bg-gray-50
              focus:outline-none
              focus:bg-gray-50
              disabled:opacity-50
              disabled:pointer-events-none
			  "
                >
                  Reject
                </button>

                {/* Accept Button */}
                <button
                  onClick={handleAccept}
                  type="button"
                  className="
			flex-1
			sm:flex-none
			justify-center
			py-2
              px-3
              inline-flex
              items-center
              gap-x-2
              text-sm
              font-medium
              rounded-lg
              border
              border-transparent
              bg-gradient-to-tl
              from-black
              to-gray-800
              hover:from-gray-800
              hover:to-black
              text-white
              focus:outline-none
              disabled:opacity-50
              disabled:pointer-events-none
            "
                >
                  Accept
                </button>

                {/* Close Icon */}
                <div
                  className="bg-gray-100 rounded-lg p-2 cursor-pointer hover:bg-gray-200"
                  onClick={() => setDismiss(true)}
                >
                  <svg
                    className="shrink-0 size-5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
