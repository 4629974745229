import { toast } from 'react-toastify';

const position = 'bottom-right';

const SuccessToast = (message) => {
  const options = {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  toast.success(message, options);
};

const ErrorToast = (message) => {
  const options = {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  toast.error(message, options);
};

export { SuccessToast, ErrorToast };
